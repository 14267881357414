import React, { useEffect, useState } from 'react';
import { Card } from 'theme-ui'
import { Layout, Stack, Main, Sidebar } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Sticky from '@components/Sticky'
import Seo from '@widgets/Seo'
import AuthorCompact from '@widgets/AuthorCompact'
import { Adsense } from '../components/Hero/googleAdsense'
import TableOfContentsCompact from '@widgets/TableOfContentsCompact'
import { Link } from 'gatsby';
import { Box, Label, Input, Textarea, Button, Message, Spinner, Text} from 'theme-ui'
import {
  PostHead,
  PostImage,
  PostBody,
  PostComments,
  PostCommentsFacebook,
  PostCommentsGraph,
  PostTagsShare,
  PostFooter
} from '@widgets/Post'



const Post = ({
  data: { post, tagCategoryPosts, tagPosts, categoryPosts, previous, next },
  ...props
}) => {
//   const [adsenseLoaded, setAdsenseLoaded] = useState(false);

// useEffect(() => {
//   setAdsenseLoaded(true);
//   console.log('Adsense loaded');
// }, []);

  const relatedPosts = [
    ...(tagCategoryPosts ? tagCategoryPosts.nodes : []),
    ...(tagPosts ? tagPosts.nodes : []),
    ...(categoryPosts ? categoryPosts.nodes : [])
  ]
  const { pageContext: { services = {}, siteUrl } = {} } = props

  // if (!adsenseLoaded) {
  //   return (
  //     <div style={{ textAlign: 'center', padding: '50px', fontWeight:'800', color:'lightgray',fontFamily:'helvetica',textAlign:'center' }}>
  //       <p>Cargando...</p>
  //     </div>
  //   );
  // }

  return (
    <Layout {...props}>
       {/* <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2292291997787849"
     crossOrigin="anonymous"></script> */}
      <div>
      <Adsense />
      </div>
      {/* <Adsense path={props.path} onLoad={() => setAdsenseLoaded(true)} /> */}
      <Divider />
      <Seo {...post} siteUrl={siteUrl} />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PostHead {...post} />
      </Stack>
      <Divider />
      <div>
      <Adsense />
      </div>
      <Stack effectProps={{ fraction: 0 }}>
        <Main>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingBottom:'20px'}}>
        <Link to="/solicitar-tarjeta-de-credito-bbva">
          <Button style={{margin: '5px', width: 'auto', padding: '10px 50px'}} variant="white">Tarjeta de Crédito BBVA: Pedila Online 🔥 </Button>
        </Link>
        <Link to="/credito-hipotecario">
          <Button style={{margin: '5px', width: 'auto', padding: '10px 50px'}} variant="white">¿Cómo sacar tu Crédito Hipotecario? 💸🏡 </Button>
        </Link>
        <Link to="/solicitar-tarjeta-de-credito-santander">
          <Button style={{margin: '5px', width: 'auto', padding: '10px 50px'}} variant="white">Solicita tu Tarjeta de Crédito Santander 💳 </Button>
        </Link> 
      </div>
          <Card variant='paper' sx={{ display: [`block`] }}>
            <PostImage {...post} inCard />
            <PostBody {...post} />
            <PostTagsShare {...post} location={props.location} />
            {services.disqus && <PostComments {...post} />}
            {services.graphComment && <PostCommentsGraph {...post} />}
            {services.facebookComment && (
              <PostCommentsFacebook {...post} siteUrl={siteUrl} />
            )}
            <div>
             <Adsense />
          </div>
            <PostFooter {...{ previous, next }} />
          </Card>
        </Main>
        <Sidebar>
          <AuthorCompact author={post.author} omitTitle />
          <Divider />
          <Sticky>
            {post.tableOfContents?.items && (
              <>
                <TableOfContentsCompact {...post} />
                <Divider />
              </>
            )}
            {post.category && (
              <CardList
                title='Últimas Noticias'
                nodes={relatedPosts}
                variant='horizontal-aside'
                limit={6}
                omitMedia
                omitCategory
                distinct
                aside
              />
            )}
          </Sticky>
        </Sidebar>
      </Stack>
    </Layout>
  )
}

export default Post
