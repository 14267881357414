import React from 'react'
import { Link as GLink } from 'gatsby'
import { Link, Text } from 'theme-ui'
import TextList from '@components/TextList'
import PageTitle from '@components/PageTitle'
import { format } from 'date-fns'
import esLocale from 'date-fns/locale/es'

const styles = {
  item: {
    display: `inline-block`
  }
}


export const PostHead = ({ title, author, date, timeToRead, category }) => {
  // Formatear la fecha en español
const formattedDate = date ? format(new Date(date), "dd'/'MM'/'yyyy'", { locale: esLocale }) : null;
  const info = (
    <TextList>
      {author && author.slug && (
        <Text sx={styles.item}>
          {`Por `}
          <Link variant='mute' as={GLink} to={author.slug}>
            <strong>{author.name}</strong>
          </Link>
        </Text>
      )}
      {category && category.slug && (
        <Text sx={styles.item}>
          {`Publicado en `}
          <Link variant='mute' as={GLink} to={category.slug}>
            <strong>{category.name}</strong>
          </Link>
        </Text>
      )}
      {/* {date && <Text sx={styles.item}>{date}</Text>} */}
      {formattedDate && <Text sx={styles.item}>{formattedDate}</Text>}
      {timeToRead && (
        <Text sx={{ ...styles.item, color: `error` }}>
          <strong>{timeToRead} min de lectura</strong>
        </Text>
      )}
    </TextList>
  )

  return <PageTitle header={title} running={info} />
}
